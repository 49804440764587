import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ArticlePreview } from './article-preview';

@Injectable({providedIn: 'root'})
export class NatureServerService {
  private lastTextsUrl = "/api/lasttexts";

  constructor(
    private http: HttpClient,
  ) {}

  /** Log a HeroService message with the MessageService */
  // private log(message: string) {
  //   this.messageService.add(`HeroService: ${message}`);
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string) {
    console.log(message);
  }

  fetchLastTexts(): Observable<ArticlePreview[]> {
    return this.http.get<ArticlePreview[]>(this.lastTextsUrl)
      .pipe(
        tap(_ => this.log("fetched lasttexts")),
        catchError(this.handleError<ArticlePreview[]>('getLastTexts', []))
      );
  }

  // update(hero: Hero): Observable<any> {
  //   return this.http.put(this.heroesUrl, hero, httpOptions)
  //     .pipe(
  //       tap(_ => this.log(`updated hero id=${hero.id}`)),
  //       catchError(this.handleError<any>('updateHero'))
  //     );
  // }

  //getHero(id: number): Observable<Hero> {
    // TODO: send the message _after_ fetching the hero
    //this.log(`fetched hero id=${id}`);
    //return of(HEROES.find(hero => hero.id === id));
  //}
}

