import { Component, OnInit } from '@angular/core';

import { NatureServerService } from '../nature-server.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {

  articles = []

  constructor(
    private natureServer: NatureServerService
  ) {}

  ngOnInit() {
    this.natureServer.fetchLastTexts()
      .subscribe(articles => this.articles = articles);
  }

}
